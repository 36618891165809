import { useLayoutEffect, useState } from 'react';

function useIsEdge() {
  const [isEdge, setIsEdge] = useState(false);

  useLayoutEffect(() => {
    if (window.navigator.userAgent.indexOf('Edg') > -1) {
      setIsEdge(true);
    }
  }, []);

  return isEdge;
}

export default useIsEdge;