import { Link } from 'gatsby';
import React from 'react';

import useIsEdge from '../../util/use-is-edge';

function FaqCallout() {
  const isEdge = useIsEdge();

  return (
    <section>
      <div className={'faq-container mx-auto'}>
        <div className="faq-card flex flex-col-reverse lg:flex-row justify-center items-center  text-white-100 mx-auto">
          <div className="w-full">
            <div className="flex flex-col-reverse md:flex-row items-center ">
              <div className="w-full">
                <div className="flex items-center flex-wrap">
                  <h2 className="font-mulish font-bold text-center lg:text-left mx-auto lg:ms-0">
                  Have questions about Myfembree?{' '}
                  <br className="hidden md:block" />You’ve come to the right place.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FaqCallout;
