import React, { useState } from 'react';
import useIsEdge from '../../util/use-is-edge';
import FaqAccordion from './faq-accordion/faq-accordion';
import { Link } from 'gatsby';
function FaqQuestions() {
  const isEdge = useIsEdge();

  // Definimos el array de acordeones
  const accordions = [
    {
      title: 'About Myfembree',
      items: [
        {
          subContent: false,
          type: '',
          title: 'What is Myfembree?',
          content:
            'Myfembree is not a surgery, procedure, injection, or painkiller. It is a once-daily pill to reduce heavy menstrual bleeding from uterine fibroids or moderate to severe endometriosis pain in premenopausal women.'
        }
      ]
    },
    {
      title: 'About uterine fibroids & endometriosis',
      items: [
        {
          subContent: false,
          type: '',
          title: 'What are uterine fibroids?',
          content:
            'Uterine fibroids are also known as uterine myoma. They are non-cancerous growths that are found in and around the uterus.'
        },
        {
          subContent: false,
          type: '',
          title: 'What are the signs and symptoms of uterine fibroids? ',
          content:
            'The most common sign and symptom of uterine fibroids is heavy period bleeding (bleeding for more than 7 days and requiring more than one pad/tampon at a time). Some women may experience dizziness and fatigue due to anemia caused by heavy menstrual bleeding.'
        },
        {
          subContent: false,
          type: '',
          title: 'What is endometriosis?',
          content:
            'Endometriosis occurs when tissue similar to what lines the uterus grows  outside of the uterus. This leads to lesions and inflammation, which can cause pain.'
        },
        {
          subContent: false,
          type: '',
          title: 'What are the signs and symptoms of endometriosis?',
          content:
            'Pain is a common symptom of endometriosis. Other signs might include pain during your period (dysmenorrhea), between periods (non-menstrual pelvic pain), and even during sex (dyspareunia).'
        },
        {
          subContent: false,
          type: '',
          title:
            'Can heavy menstrual bleeding due to uterine fibroids, and moderate to severe endometriosis pain, be treated without surgery?',
          content:
            'Not everyone with heavy menstrual bleeding due to uterine fibroids or with moderate to severe endometriosis pain will require surgery. Ask your gynecologist about non-surgical options, like Myfembree. Myfembree is a once-daily oral medication to reduce heavy period bleeding from uterine fibroids or moderate to severe endometriosis pain.'
        }
      ]
    },
    {
      title: 'How can Myfembree help?',
      items: [
        {
          subContent: true,
          type: 'case1',
          title: 'How does Myfembree work? ',
          content: 'Myfembree contains three key ingredients that may help reduce heavy bleeding due to fibroids or help reduce moderate to severe endometriosis pain. To learn more, explore our <em>Why Myfembree?</em> page for your condition.'
        },
        {
          subContent: false,
          type: 'content1',
          title:
            'How can Myfembree help reduce heavy bleeding from uterine fibroids?',
          content:
            ''
        },
        {
          subContent: false,
          type: 'content2',
          title:
            'How can Myfembree help reduce moderate to severe endometriosis pain?',
          content:
            ''
        },
        {
          subContent: false,
          type: '',
          title: 'What is GnRH?',
          content:
            'GnRH, or gonadotropin-releasing hormone, sends a signal to your body to create other hormones. These other hormones make uterine fibroids and endometriosis lesions grow. Myfembree blocks the signal.'
        }
      ]
    },
    {
      title: 'Getting Myfembree',
      items: [
        {
          subContent: true,
          type: 'case2',
          title:
            'How do I talk to my gynecologist about treatment with Myfembree?',
          content:
            'Heavy menstrual bleeding due to uterine fibroids, and moderate to severe endometriosis pain can significantly impact you. It’s important to talk to your gynecologist about treatment options. Take the quiz to help get the conversation going.'
        },
        {
          subContent: false,
          type: '',
          title: 'How do I get Myfembree?',
          content:
            'Once you get a uterine fibroids or endometriosis diagnosis, talk to your gynecologist to decide if Myfembree is right for you. They may need to rule <span class="inline-block">out a</span> pregnancy and recommend a non-hormonal birth control method <span class="inline-block">before you</span> start taking Myfembree.'
        },
        {
          subContent: true,
          type: 'case5',
          title: 'What does treatment with Myfembree cost?',
          content:
            'There are ways you may be able to save on Myfembree with the Myfembree Copay Assistance Program. To learn more, explore our <em>Cost & Savings</em> page.'
        }
      ]
    },
    {
      title: 'Starting Myfembree',
      items: [
        {
          subContent: true,
          type: 'case4',
          title: 'How do I start Myfembree?',
          content:
            'It’s very important to start Myfembree exactly as directed by your gynecologist. As soon as you get your next period, it’s time to start. To learn more, explore our <em>Starting Myfembree</em> page.'
        },
        {
          subContent: true,
          type: 'case3',
          title:
            'What do I need to know about safety and the possible side effects of Myfembree for heavy periods from uterine fibroids or moderate to severe endometriosis pain?',
          content:
            'When you’re considering treatment with Myfembree, it’s important to learn about possible side effects. Click on your condition below to view some of the side effects of Myfembree from the clinical trials and view Important Safety Information below.'
        }
      ]
    }
  ];

  return (
    <section>
      <div className=" mx-auto">
        <div className="faq-content mx-auto">
          {/* Mapeamos los acordeones y sus respectivos títulos */}
          {accordions.map((accordion, index) => (
            <div key={index}>
              <h3 className="font-mulish purple-125 mb-4 font-bold">
                {accordion.title}
              </h3>
              <FaqAccordion items={accordion.items} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
export default FaqQuestions;
