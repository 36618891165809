import React from 'react';

import FaqHero from '../Hero/faq-hero';
import FaqCallout from '../faq-callout/faq-callout';
import FaqQuestions from '../faq-questions/faq-questions';

export default function GlobalCostAndSavings() {

  return (
    <>

      <FaqHero />

      <FaqCallout />

      <FaqQuestions />

    </>
  );
}
