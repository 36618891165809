import React, { useState } from 'react';
import { Link } from 'gatsby';
const FaqAccordion = ({ items }: any) => {
  const [openIndexes, setOpenIndexes] = useState(
    Array(items.length).fill(false)
  );

  // Función para abrir o cerrar un acordeón específico
  const toggleAccordion = (index: any) => {
    const newOpenIndexes = [...openIndexes]; // Copiamos el array de estados actual

    // Invertimos el estado del acordeón en la posición index
    newOpenIndexes[index] = !newOpenIndexes[index];
    setOpenIndexes(newOpenIndexes); // Actualizamos el estado
  };

  const renderSubContent = (type: string) => {
    switch (type) {
      case 'case1':
        return (
          <div className="accordion-extra-content flex flex-col">
            <Link
              className="link"
              to="/uterine-fibroids/why-myfembree/#how-does-it-work"
            >
              <u>Heavy menstrual bleeding from uterine fibroids </u>
            </Link>
            <br />
            <Link
              className="link"
              to="/endometriosis/why-myfembree/#how-does-it-work"
            >
              <u>Moderate to severe endometriosis pain</u>
            </Link>
          </div>
        );
      case 'case2':
        return (
          <div className="accordion-extra-content flex flex-col">
            <Link
              className="link"
              to="/uterine-fibroids/at-the-doctor/#questions-section"
            >
              <u>Heavy menstrual bleeding from uterine fibroids </u>
            </Link>
            <br />
            <Link
              className="link"
              to="/endometriosis/at-the-doctor/#questions-section"
            >
              <u>Moderate to severe endometriosis pain</u>
            </Link>
          </div>
        );
      case 'case3':
        return (
          <div className="accordion-extra-content flex flex-col">
            <Link
              className="link"
              to="/uterine-fibroids/why-myfembree/#side-effects"
            >
              <u>Heavy menstrual bleeding from uterine fibroids </u>
            </Link>
            <br />
            <Link
              className="link"
              to="/endometriosis/why-myfembree/#side-effects"
            >
              <u>Moderate to severe endometriosis pain</u>
            </Link>
          </div>
        );
      case 'case4':
        return (
          <div className="accordion-extra-content flex flex-col">
            <Link className="link" to="/uterine-fibroids/starting-myfembree">
              <u>Heavy menstrual bleeding from uterine fibroids </u>
            </Link>
            <br />
            <Link className="link" to="/endometriosis/starting-myfembree">
              <u>Moderate to severe endometriosis pain</u>
            </Link>
          </div>
        );
      case 'case5':
        return (
          <div className="accordion-extra-content flex flex-col">
            <Link className="link" to="/uterine-fibroids/cost-and-support/">
              <u>Heavy menstrual bleeding from uterine fibroids </u>
            </Link>
            <br />
            <Link className="link" to="/endometriosis/cost-and-support/">
              <u>Moderate to severe endometriosis pain</u>
            </Link>
          </div>
        );
      default:
        return null;
    }
  };

  const renderContent = (type: string, content: string) => {
    switch (type) {
      case 'content1':
        return (
          <div className="accordion-body">
            Myfembree was studied and proven effective in reducing heavy period
            bleeding from uterine fibroids in premenopausal women in two
            clinical studies. To learn more, explore our{' '}
            <Link className="link" to="/endometriosis/why-myfembree">
              <em>
                <u>Why Myfembree?</u>
              </em>
            </Link>{' '}
            page.
          </div>
        );
      case 'content2':
        return (
          <div className="accordion-body">
            Myfembree was studied and proven effective in reducing moderate to
            severe endometriosis pain in premenopausal women in two clinical
            studies. To learn more, explore our{' '}
            <Link className="link" to="/endometriosis/why-myfembree">
              <em>
                <u>Why Myfembree?</u>
              </em>
            </Link>{' '}
            page.
          </div>
        );
      default:
        return (
          <div
            className="accordion-body"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        );
    }
  };

  return (
    <div className="faq-accordion mx-auto">
      {items.map((item: any, index: any) => (
        <div key={index} className=" rounded">
          {index === 0 && <hr />}
          <div
            className="faq-accordion-item flex cursor-pointer items-start justify-between md:items-center"
            onClick={() => toggleAccordion(index)}
          >
            <div className="accordion-title self-center font-semibold">
              {item.title}
            </div>
            <div className="svg-icon pr-3">
              {openIndexes[index] ? (
                <svg
                  id="Component_62_1"
                  data-name="Component 62 – 1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="51.343"
                  height="51.343"
                  viewBox="0 0 51.343 51.343"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <ellipse
                        id="Ellipse_3"
                        data-name="Ellipse 3"
                        cx="25.671"
                        cy="25.671"
                        rx="25.671"
                        ry="25.671"
                      />
                    </clipPath>
                    <linearGradient
                      id="linear-gradient"
                      x1="-1.922"
                      y1="0.252"
                      x2="-1.919"
                      y2="0.252"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stopColor="#5c068c" />
                      <stop offset="1" stopColor="#2f1373" />
                    </linearGradient>
                  </defs>
                  <g id="Group_4677" data-name="Group 4677">
                    <g
                      id="Group_4676"
                      data-name="Group 4676"
                      clipPath="url(#clip-path)"
                    >
                      <path
                        id="Path_2299"
                        data-name="Path 2299"
                        d="M-26.382,29.093-72.9-26.316-17.491-72.8,29.027-17.391Z"
                        transform="translate(47.608 47.508)"
                        fill="url(#linear-gradient)"
                      />
                    </g>
                  </g>
                  <line
                    id="Line_281"
                    x2="27.635"
                    transform="translate(11.854 25.671)"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="5"
                  />
                </svg>
              ) : (
                <svg
                  id="Component_61_1"
                  data-name="Component 61 – 1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="51.343"
                  height="51.343"
                  viewBox="0 0 51.343 51.343"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <ellipse
                        id="Ellipse_4"
                        data-name="Ellipse 4"
                        cx="25.671"
                        cy="25.671"
                        rx="25.671"
                        ry="25.671"
                      />
                    </clipPath>
                    <linearGradient
                      id="linear-gradient"
                      x1="-3.009"
                      y1="1.531"
                      x2="-3.006"
                      y2="1.531"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stopColor="#5c068c" />
                      <stop offset="1" stopColor="#2f1373" />
                    </linearGradient>
                  </defs>
                  <g id="Group_4682" data-name="Group 4682">
                    <g id="Group_4681" data-name="Group 4681">
                      <g id="Group_4680" data-name="Group 4680">
                        <g
                          id="Group_4679"
                          data-name="Group 4679"
                          clipPath="url(#clip-path)"
                        >
                          <path
                            id="Path_2303"
                            data-name="Path 2303"
                            d="M-26.382,29.093-72.9-26.316-17.491-72.8,29.027-17.391Z"
                            transform="translate(47.608 47.508)"
                            fill="url(#linear-gradient)"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <line
                    id="Line_280"
                    y2="27.153"
                    transform="translate(25.671 11.854)"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="5"
                  />
                  <line
                    id="Line_281"
                    x2="27.635"
                    transform="translate(11.854 25.671)"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="5"
                  />
                </svg>
              )}
            </div>
          </div>
          <div
            className={`transition-max-height overflow-hidden duration-500 ${openIndexes[index] ? 'max-h-screen' : 'max-h-0'}`}
          >
            {renderContent(item.type, item.content)}
            {/* <div
              className="accordion-body"
              dangerouslySetInnerHTML={{ __html: item.content }}
            ></div> */}
            {item.subContent && renderSubContent(item.type)}
          </div>
          <hr />
        </div>
      ))}
    </div>
  );
};

export default FaqAccordion;
