import React from 'react';

import FaqBg from '../../assets/images/faq/faq-hero-bg.jpg';
import FaqBgWebp from '../../assets/images/faq/faq-hero-bg.webp';
import FaqBg2x from '../../assets/images/faq/faq-hero-bg@2x.jpg';
import FaqBgWebp2x from '../../assets/images/faq/faq-hero-bg@2x.webp';

import FaqBg_s from '../../assets/images/faq/faq-hero-bg_s.jpg';
import FaqBgWebp_s from '../../assets/images/faq/faq-hero-bg_s.webp';
import FaqBg2x_s from '../../assets/images/faq/faq-hero-bg@2x_s.jpg';
import FaqBgWebp2x_s from '../../assets/images/faq/faq-hero-bg@2x_s.webp';



function FaqHero() {
  return (
    <section className="relative faq-info-hero">
      <div className="container-faq mx-auto">
        <h1 className="text-white-100 flex flex-col md:flex-row justify-between md:items-center">
          <span className="text-center md:text-left">
          Part of finding relief{' '}
          <br className="hidden md:block" />is making sure my questions are answered.
            {/* I didn’t have to let cost get in the way{' '}
            <br className="hidden md:block" /> of relief. */}
          </span>
        </h1>

        <div className="absolute left-0 top-0 flex flex-col md:flex-row starting-mf-hero-image">
          <picture className="">
            <source
              srcSet={`${FaqBgWebp_s}, ${FaqBgWebp2x_s} 2x`}
              media="(max-width: 672px)"
              type="image/webp"
            />

            <source
              srcSet={`${FaqBg_s}, ${FaqBg2x_s} 2x`}
              media="(max-width: 672px)"
            />

            <source
              srcSet={FaqBgWebp}
              media="(max-width: 1152px)"
              type="image/webp"
            />

            <source srcSet={FaqBg} media="(max-width: 1152px)" />

            <source
              srcSet={FaqBgWebp2x}
              media="(min-width: 1152px)"
              type="image/webp"
            />

            <source srcSet={FaqBg2x} media="(min-width: 1152px)" />

            <img
              className="w-100 img-fluid"
              src={FaqBg}
              alt="Actor portrayal of two women outside."
              width={1440}
              height={612}
              fetchpriority="high"
            />
          </picture>
        </div>
      </div>
    </section>
  );
}

export default FaqHero;
